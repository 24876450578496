/* Popup.css */
.welcome{
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: wheat;
    z-index: 1000;
    text-align: center;
    width: 80%;
  }
  
  .welcome button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color:#E34B00;
    color:#710100 ;
    border: none;
    cursor: pointer;
    border-radius: 8px;
  }
  h1{
    color: #710100;
    font-size: 22px;
    
  }
  h2{
    color: #710100;
    font-size: 18px;
    margin-bottom: 1px;
  }
  @media (max-height:721px) {
    p{
      font-size: 14px;
    }
  }